.flex-section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.flex-col-scroll {
    flex-grow: 1;
    overflow: auto;
    min-height: 100%;
}

.team-form-win {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: green;
    color: white;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    margin-right: 4px;
    font-size: 14px;
}

.team-form-win-penalty {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #006400;
    color: white;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    margin-right: 4px;
    font-size: 14px;
}


.team-form-lose {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: red;
    color: white;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    margin-right: 4px;
    font-size: 14px;
}

.team-form-lose-penalty {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #BB0000;
    color: white;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    margin-right: 4px;
    font-size: 14px;
}

.team-form-draw {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: grey;
    color: white;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    margin-right: 4px;
    font-size: 14px;
}

.team-form-lose-contumacy {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: indianred;
    color: white;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    margin-right: 4px;
    font-size: 14px;
}

.team-form-win-contumacy {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: limegreen;
    color: white;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    margin-right: 4px;
    font-size: 14px;
}