.home-team-player-dress {
    background: url("https://www.kdesehraje.cz/media/white-dress2.png") no-repeat center center;
    height: 30px;
    background-size: 40px 40px;
}

.away-team-player-dress {
    background: url("https://www.kdesehraje.cz/media/red-dress2.png") no-repeat center center;
    height: 30px;
    background-size: 40px 40px;
}