.header-gradient
{
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.83) 21%, rgba(255, 255, 255, 0.72) 35%, rgba(209, 209, 209, 0.62) 47%, rgba(133, 130, 133, 0.3) 86%, rgba(246, 243, 246, 1) 99%, rgba(255, 252, 255, 1) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(21%, rgba(255, 255, 255, 0.83)), color-stop(35%, rgba(255, 255, 255, 0.72)), color-stop(47%, rgba(209, 209, 209, 0.62)), color-stop(86%, rgba(133, 130, 133, 0.3)), color-stop(99%, rgba(246, 243, 246, 1)), color-stop(100%, rgba(255, 252, 255, 1)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.83) 21%, rgba(255, 255, 255, 0.72) 35%, rgba(209, 209, 209, 0.62) 47%, rgba(133, 130, 133, 0.3) 86%, rgba(246, 243, 246, 1) 99%, rgba(255, 252, 255, 1) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.83) 21%, rgba(255, 255, 255, 0.72) 35%, rgba(209, 209, 209, 0.62) 47%, rgba(133, 130, 133, 0.3) 86%, rgba(246, 243, 246, 1) 99%, rgba(255, 252, 255, 1) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.83) 21%, rgba(255, 255, 255, 0.72) 35%, rgba(209, 209, 209, 0.62) 47%, rgba(133, 130, 133, 0.3) 86%, rgba(246, 243, 246, 1) 99%, rgba(255, 252, 255, 1) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.83) 21%, rgba(255, 255, 255, 0.72) 35%, rgba(209, 209, 209, 0.62) 47%, rgba(133, 130, 133, 0.3) 86%, rgba(246, 243, 246, 1) 99%, rgba(255, 252, 255, 1) 100%);

}